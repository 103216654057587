<template>
    <v-select :items="accounts"
              :value="activeAccountId"
              @input="setActiveAccount"
              hide-details>
    </v-select>
</template>
<script>
    import { mapState, mapGetters } from 'vuex';

    export default {
        name: 'account-selector',
        computed: {
            ...mapState('organisation/submittable', ['activeAccountId']),
            ...mapGetters({
                accounts: 'organisation/submittable/activeAccountOptions',
            }),
        },
        methods: {
            setActiveAccount(newValue) {
                this.$store
                    .dispatch('organisation/submittable/setActiveAccount', {
                        accountId: newValue,
                    })
                    .catch(err => this.$handleError(err));
            },
        },
    };
</script>