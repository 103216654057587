<template>
    <portal to="page-filters">
        <v-card outlined>
            <v-card-title class="pt-2">Submissions</v-card-title>
            <v-card-subtitle class="py-0">Load {{pageSizeBound}} submissions at a time:</v-card-subtitle>
            <v-card-text>
                <v-select v-model="pageSizeBound"
                          :items="[10, 20, 50, 100, 200]"
                          hide-details
                          class="py-0"></v-select>
            </v-card-text>
            <v-card-subtitle class="py-0">Already imported submissions:</v-card-subtitle>
            <v-card-text>
                <v-chip-group v-model="showImportedBound"
                              mandatory
                              hide-details
                              active-class="primary--text">
                    <v-chip :value="true">Show</v-chip>
                    <v-chip :value="false">Hide</v-chip>
                </v-chip-group>
            </v-card-text>
            <v-card-subtitle class="py-0">Search by title and author:</v-card-subtitle>
            <v-card-text>
                <v-text-field v-model="searchTermBound"
                              label="Title, name or email address"
                              hide-details
                              append-icon="search"></v-text-field>
            </v-card-text>
            <v-card-subtitle class="py-0">Show submissions of status:</v-card-subtitle>
            <v-card-text>
                <v-chip-group v-model="statusBound"
                              mandatory
                              hide-details
                              column>
                    <v-chip v-for="status in statuses"
                            :key="status"
                            :value="status"
                            :color="statusGetColor(status)"
                            text-color="white"
                            label
                            class="text-capitalize submittable-new-opacity">{{status}}</v-chip>
                    <v-chip key="all"
                            value="all"
                            color="primary"
                            text-color="white"
                            label
                            class="text-capitalize submittable-new-opacity">All</v-chip>
                </v-chip-group>
            </v-card-text>
            <v-card-subtitle class="py-0">Show submissions in categories:</v-card-subtitle>
            <v-card-text>
                <v-select v-if="categoriesLoading"
                          label="Selected Categories"
                          loading
                          disabled
                          key="loading-cats"></v-select>
                <v-select v-else
                          v-model="categoriesBound"
                          :items="categoryList"
                          item-value="category_id"
                          item-text="name"
                          label="Selected Categories"
                          multiple
                          key="loaded-cats"
                          hide-details>
                    <template #selection="{ item }">
                        <v-chip v-if="item.category_id === -1"
                                color="primary">{{item.name}}</v-chip>
                        <category-chip v-else-if="item._id"
                                       :category-id="item._id"></category-chip>
                        <category-chip-submittable v-else
                                                   :submittable-category="item"
                                                   submittable-id-as-value
                                                   disable-edit></category-chip-submittable>
                    </template>
                </v-select>
            </v-card-text>
            <v-card-subtitle class="py-0">Sort submissions by:</v-card-subtitle>
            <v-card-text>
                <v-chip-group v-model="submissionsSortBound"
                              mandatory
                              hide-details
                              column
                              active-class="primary--text">
                    <v-chip value="submitted">Submitted Date</v-chip>
                    <v-chip value="category">Category</v-chip>
                    <v-chip value="submitter">Submitter</v-chip>
                </v-chip-group>
                <v-chip-group v-model="submissionsSortAscBound"
                              mandatory
                              hide-details
                              active-class="primary--text">
                    <v-chip :value="true">Ascending</v-chip>
                    <v-chip :value="false">Descending</v-chip>
                </v-chip-group>
            </v-card-text>
        </v-card>
    </portal>
</template>
<script>
    import { mapActions, mapState } from 'vuex';
    import statusColors from '@/helpers/statusColors';

    import categoryChip from '@/components/category-chip';
    import categoryChipSubmittable from './category-chip-submittable';

    function generateField(optionName) {
        return {
            get() {
                return this[optionName];
            },
            set(newVal) {
                this.updateSettings({ [optionName]: newVal });
            },
        };
    }

    export default {
        name: 'submissions-new-filters',
        components: {
            categoryChip,
            categoryChipSubmittable,
        },
        data: () => ({
            categoriesLoading: false,
        }),
        computed: {
            categoriesBound: {
                get() {
                    return this.categories;
                },
                set(newVal) {
                    if (
                        this.categoriesAllSelected &&
                        this.categorySelectionIsAll(newVal)
                    )
                        return;

                    if (this.categoriesAllSelected)
                        while (newVal.indexOf(-1) >= 0)
                            newVal.splice(newVal.indexOf(-1), 1);

                    if (newVal.indexOf(-1) >= 0) newVal = [-1];

                    this.updateSettings({ categories: newVal });
                },
            },
            categoriesAllSelected() {
                return this.categorySelectionIsAll(this.categories);
            },
            categoryList() {
                return [
                    { category_id: -1, name: 'All Categories' },
                    ...Object.values(this.submittableCategories)
                        .map(c => c.category_id)
                        .map(id => ({
                            _id: this.importedCategories.submittableMappings[id],
                            category_id: id,
                        }))
                        .map(({ category_id, _id }) =>
                            _id
                                ? { ...this.importedCategories[_id], category_id }
                                : this.submittableCategories[category_id]
                        )
                        .sort((a, b) => a.start_date < b.start_date ? 1 : -1),
                ];
            },
            pageSizeBound: generateField('pageSize'),
            searchTermBound: generateField('searchTerm'),
            showImportedBound: generateField('showImported'),
            statusBound: generateField('status'),
            submissionsSortBound: generateField('submissionsSort'),
            submissionsSortAscBound: generateField('submissionsSortAscending'),
            statuses() {
                return Object.keys(statusColors).filter(s => s !== 'unknown');
            },
            ...mapState({ importedCategories: 'categories' }),
            ...mapState('organisation/submittable', {
                submittableCategories: 'categories',
            }),
            ...mapState('organisation/submittable/submissions/filters', [
                'categories',
                'pageSize',
                'searchTerm',
                'showImported',
                'status',
                'submissionsSort',
                'submissionsSortAscending',
            ]),
        },
        methods: {
            categorySelectionIsAll(selection) {
                return selection.length === 1 && selection[0] === -1;
            },
            categoryGetIcon(id) {
                return this.categoriesBound.indexOf(id) >= 0 ? 'check' : 'clear';
            },
            statusGetColor(status) {
                return statusColors[status];
            },
            ...mapActions('organisation/submittable/submissions/filters', [
                'updateSettings',
            ]),
        },
        created() {
            if (!Object.keys(this.submittableCategories).length) {
                this.categoriesLoading = true;
                this.$store
                    .dispatch('organisation/submittable/categoriesLoad')
                    .then(() => {
                        this.categoriesLoading = false;
                    });
            }
        },
    };
</script>
<style>
    .submittable-new-opacity {
        opacity: 0.333333;
    }
    .submittable-new-opacity.v-chip--active {
        opacity: 1;
    }
</style>