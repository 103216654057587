<template>
    <app-navigation>
        <template #toolbar>
            <account-selector class="mr-2"
                              style="max-width: 150px;"></account-selector>
            <span class="text-capitalize">{{status}} Submissions</span>
            <span v-if="unproccessedCount !== null"
                  class="ml-1">({{shownSubs}}/{{unproccessedCount}}<span v-if="!showImported && alreadyImportedCount"> - {{alreadyImportedCount}} already imported</span>)</span>
            <v-spacer></v-spacer>
            <template v-if="showImportedCount">
                <v-divider vertical
                           class="mx-2"></v-divider>
                <span class="mr-1">Imported: </span>
                <category-chip v-for="cat in importedCategoryCount"
                               :key="cat._id"
                               :category-id="cat._id"
                               :count="cat.count"
                               breakpoint="lg"
                               class="mr-1"></category-chip>
                <v-divider vertical
                           class="mx-2">
                </v-divider>
            </template>
            <v-btn @click.stop="togglePageFilters()"
                   text>
                <v-icon>fa-sliders-h</v-icon>
            </v-btn>
        </template>
        <div v-if="!fetchingSubmissions && !filterChangetimeout && submissionList.length === 0"
             class="d-flex align-center"
             style="height: 100%;">
            <div class="mx-auto text-center"
                 style="position: relative; top: -12vh">
                <h2>No Submissions To Display</h2>
                <em>Try adjusting how you are filtering the submisions</em>
            </div>
        </div>
        <v-container v-else>
            <v-row justify="center">
                <v-col cols="12"
                       lg="8">
                    <v-alert v-if="!activeAccount.projectToFeed"
                             type="error"
                             style="position: sticky; top: 5.25em; z-index: 1000"
                             elevation="12"
                             border="bottom"
                             dismissible>
                        <p>The Submittable account <em>{{activeAccount.name}}</em> is not associated with any project. </p>
                        <p class="mb-0">As I have nowhere to save these submissions, I've disabled all the importing on this page.</p>
                    </v-alert>
                    <submission-details v-for="_id in submissionList"
                                        :key="_id"
                                        :submission-id="_id"
                                        class="mb-4"></submission-details>
                    <template v-if="submissionList.length === 0">
                        <v-skeleton-loader v-for="i in 10"
                                           :key="i"
                                           type="card-heading,text,table-thead,table-row,actions"
                                           class="mb-4"></v-skeleton-loader>
                    </template>
                    <div class="d-flex">
                        <v-btn class="mx-auto"
                               :loading="fetchingSubmissions"
                               @click="loadMore">Load more</v-btn>
                    </div>
                </v-col>
            </v-row>
        </v-container>
        <submissions-list-filters></submissions-list-filters>
    </app-navigation>
</template>

<script>
    import { mapGetters, mapMutations, mapState } from 'vuex';

    import accountSelector from '@/modules/submittable/components/account-selector';
    import appNavigation from '@/components/app-navigation';
    import categoryChip from '@/components/category-chip';
    import submissionDetails from '@/modules/submittable/components/submission-details';
    import submissionsListFilters from '@/modules/submittable/components/submissions-list-filters';

    export default {
        name: 'new-submissions',
        components: {
            accountSelector,
            appNavigation,
            categoryChip,
            submissionDetails,
            submissionsListFilters,
        },
        computed: {
            shownSubs() {
                return this.submissionList.length;
            },
            showImportedCount() {
                const cats = this.importedCategoryCount;

                for (var prop in cats) 
                    if (Object.prototype.hasOwnProperty.call(cats, prop)) 
                        return true;

                return false;
            },
            ...mapGetters('organisation/submittable', ['activeAccount']),
            ...mapState('organisation/submittable/submissions', [
                'fetchingSubmissions',
                'totalInSubmitable',
            ]),
            ...mapGetters('organisation/submittable/submissions', [
                'alreadyImportedCount',
                'unproccessedCount',
                'importedCategoryCount',
                'submissionById',
                'submissionList',
            ]),
            ...mapState('organisation/submittable/submissions/filters', [
                'showImported',
                'status',
                'filterChangetimeout',
            ]),
        },
        methods: {
            loadMore() {
                if (!this.fetchingSubmissions) {
                    this.$store
                        .dispatch('organisation/submittable/submissions/fetch')
                        .catch(err => this.$handleError(err));
                }
            },
            ...mapMutations(['togglePageFilters']),
        },
        watch: {
            // If the page is unset, that means the account was changed
            // But the account change doesn't know this page is open, so
            // we need to trigger the next page load
            totalInSubmitable(newTotal) {
                // this.timeout indicates that filters are being changed and so we should hold off
                if (newTotal === null && !this.filterChangetimeout) this.loadMore();
            },
        },
        created() {
            if (this.submissionList.length === 0) this.loadMore();
        },
    };
</script>