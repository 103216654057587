<template>
    <category-chip-editable :submittable-category-id="submittableCategory.category_id"
                            is-submittable-category
                            :submittable-id-as-value="submittableIdAsValue"
                            :close="close"
                            :close-icon="closeIcon"
                            :disable-edit="disableEdit"></category-chip-editable>
</template>
<script>
    import { mapGetters } from 'vuex';
    import categoryChipEditable from '@/components/category-chip-editable';

    export default {
        name: 'category-chip-submittable',
        components: {
            categoryChipEditable,
        },
        props: {
            submittableCategory: {
                type: Object,
                required: true,
            },
            close: Boolean,
            closeIcon: String,
            disableEdit: Boolean,
            submittableIdAsValue: Boolean,
        },
        computed: {
            mappedCatgoryId() {
                return this.mapCategoryId(this.submittableCategory.category_id);
            },
            ...mapGetters('categories', {
                mapCategoryId: 'getIdForSubmittableCategory',
            }),
        },
        created() {
            if (!this.mappedCatgoryId)
                this.$store.commit(
                    'categories/addUnknown',
                    this.submittableCategory
                );
        },
    };
</script>