<template>
    <v-list dense
            subheader>
        <file-item v-for="file in files"
                   :key="file.id"
                   :id="file.id"
                   :name="file.fileName"
                   @file-selected="$emit('file-selected', $event)">
        </file-item>
    </v-list>
</template>
<script>
    import fileItem from '@/components/file-item';

    export default {
        name: 'submission-files',
        components: {
            fileItem,
        },
        props: {
            files: {
                type: Array,
                required: true,
            },
        },
    };
</script>