<template>
    <v-btn tag="a"
           text
           :href="href"
           target="_blank"
           :disabled="disabled">
        {{text}}
        <v-icon size="8"
                class="ml-1">fa-external-link-alt</v-icon>
    </v-btn>
</template>
<script>
    export default {
        name: 'button-external-link',
        props: {
            text: {
                type: String,
                required: true,
            },
            href: {
                type: String,
                required: true,
            },
            disabled: Boolean,
        },
    };
</script>