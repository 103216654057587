<template>
    <v-card>
        <v-card-title class="justify-space-between flex-nowrap">
            <div class="text-truncate pr-2"
                 :title="submission.title">{{submission.title}}</div>
            <div class="d-flex ml-6">
                <v-icon v-if="submission.is_archived"
                        class="mr-2"
                        title="Archived">fa-archive</v-icon>
                <category-chip-submittable :submittable-category="submission.category"
                                           class="font-weight-regular mr-2"></category-chip-submittable>
                <v-chip class="overflow-visible mr-2"
                    label
                    :color="hasAddOnPayment ? 'green' : ''"
                    :text-color="hasAddOnPayment ? 'white' : ''">${{submission.payment.amount}}</v-chip>
                <status-indicator :status="submission.status"></status-indicator>
            </div>
        </v-card-title>
        <v-card-subtitle class="d-flex justify-space-between flex-nowrap pt-1">
            <div class="text-truncate mr-2"
                 :title="`${submission.submitter.first_name} ${submission.submitter.last_name}`">
                <em>by</em> {{submission.submitter.first_name}} {{submission.submitter.last_name}}
            </div>
            <div>
                <span class="d-none d-md-inline mr-1">Submitted:</span>
                <span>{{createdDate}}</span>
            </div>
        </v-card-subtitle>
        <v-card-text>
            <submission-files :files="files"
                              @file-selected="downloadFile"></submission-files>
        </v-card-text>
        <v-card-text v-if="showForm"
                     class="pt-0">
            <submittable-fields :fields="submission.form.items"></submittable-fields>
        </v-card-text>
        <card-actions>
            <v-btn v-if="alreadyImported"
                   disabled
                   color="primary"
                   text>Already Imported</v-btn>
            <v-btn v-else
                   @click="importSub"
                   :loading="loading"
                   :disabled="!activeAccount.projectToFeed"
                   color="primary"
                   text>Import</v-btn>
            <template #secondary>
                <v-switch v-model="showForm"
                          hide-details
                          label="Show form"
                          class="mx-2 mt-0"></v-switch>
                <v-spacer></v-spacer>
                <btn-external text="Submittable"
                              :href="submission.link"></btn-external>
            </template>
        </card-actions>
    </v-card>
</template>

<script>
    import { mapGetters, mapState } from 'vuex';

    import btnExternal from '@/components/button-external-link';
    import categoryChipSubmittable from './category-chip-submittable';
    import submittableFields from '@/components/submittable-fields';
    import statusIndicator from '@/components/status-indicator';
    import submissionFiles from './submission-files';

    export default {
        name: 'submission-details',
        components: {
            btnExternal,
            categoryChipSubmittable,
            statusIndicator,
            submissionFiles,
            submittableFields,
        },
        props: {
            submissionId: {
                type: Number,
                required: true,
            },
        },
        data() {
            return {
                showForm: false,
                loading: false,
            };
        },
        computed: {
            createdDate() {
                return this.$displayDate(this.submission.date_created);
            },
            files() {
                const firstFileField = this.submission.form.items.find(f => f.type === 'FILE_UPLOAD');
                return firstFileField
                    ? firstFileField.files
                    : [];
            },
            hasAddOnPayment() {
                return this.submission.form.items.find(i => i.label.startsWith("Add-on Payment"));
            },
            ...mapState('organisation/submittable/submissions', {
                alreadyImported(state) {
                    const exists = state.existingSubmissionIds;
                    return exists ? exists.indexOf(this.submissionId) > -1 : false;
                },
                submission(state) {
                    return state.submissions[this.submissionId];
                },
            }),
            ...mapGetters('organisation/submittable', ['activeAccount']),
        },
        methods: {
            downloadFile(fileId) {
                this.$store
                    .dispatch('organisation/submittable/submissions/downloadFile', {
                        submissionId: this.submissionId,
                        fileId,
                    })
                    .catch(err => this.$handleError(err));
            },
            importSub() {
                this.loading = true;

                this.$store
                    .dispatch(
                        'organisation/submittable/submissions/importSub',
                        this.submissionId
                    )
                    .then(() => {
                        this.loading = false;
                    })
                    .catch(err => this.$handleError(err));
            },
        },
    };
</script>

<style scoped>
    .max-width-15 {
        max-width: 15vw;
    }
</style>